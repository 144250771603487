import { createFormFieldConversions } from 'FrontRoyalForm';
import moment from 'moment-timezone';
import { cohortsApi } from 'Cohorts';
import { mappable } from './helpers';

function convertTimestampWithZone(name) {
    const entry = {};
    entry[name] = {
        fromForm: values => (values.date_tbd && name !== 'start_time' ? null : values[name] && values[name] / 1000),
        toForm: values => {
            const momentous = moment(values[name] ? values[name] * 1000 : undefined);
            return values.timezone ? momentous.tz(values.timezone) : momentous;
        },
    };
    return entry;
}

const [toForm, fromForm] = createFormFieldConversions({
    // Date related fields
    ...convertTimestampWithZone('start_time'),
    ...convertTimestampWithZone('end_time'),
    date_tbd_description: {
        fromForm: values => (values.date_tbd && values.date_tbd_description) || null,
    },

    // The record has place_id and place_details, but we need to be able to
    // bind a single value to the SingleLocationInput, so we combine those
    // into `place`.
    //
    // If the current event type is not mappable, then we unset the location before
    // saving.  This is better than unsetting it right when someone changes the event
    // type, because we don't clear the location if someone accidentally picked an
    // unmappable type.
    place_id: {
        fromForm: values => (mappable(values) && values.place && values.place.id) || null,
    },
    place_details: {
        fromForm: values => (mappable(values) && values.place && values.place.details) || {},
    },
    location_name: {
        fromForm: values => (mappable(values) && values.location_name) || null,
    },
    timezone: {
        fromForm: values => (mappable(values) && values.timezone) || null,
    },
    place: {
        toForm: values =>
            values.place_id && {
                id: values.place_id,
                details: values.place_details,
            },
    },

    // Before using this pattern in another situation, see the
    // comment in StudentNetworkEventPreview.jsx, where we have to
    // convert this in a different way for use in the preview
    image: {
        fromForm: values => {
            if (values.image) {
                return values.image.file || values.image.s3_asset;
            }
            return null;
        },
        toForm: values => {
            const image = values.image;
            return {
                s3_asset: image,

                // see FileInput for why we return a function here
                getUrl: () => image && image.formats.original.url,
            };
        },
    },

    accessRules: {
        toForm: async (values, { injector }) => {
            const Cohort = injector.get('Cohort');
            const cohorts = await cohortsApi.makeRequest('getCohortsForAdmin');

            const {
                target_institution_ids: initialInstitutionIds,
                target_program_types: initialProgramTypes,
                target_cohort_ids: initialCohortIds,
            } = values;

            const accessRules = {
                // Because the first two fields are bound to checkboxes, it is
                // convenient to convert them to objects. The cohorts are bound
                // to a select, so we use an array.
                targetInstitutionIds: {},
                targetProgramTypes: {},

                // FIXME: add a comment about how this gets populated
                targetCohortIdsByInstitution: {},
            };
            initialInstitutionIds.forEach(id => {
                accessRules.targetInstitutionIds[id] = true;
            });
            initialProgramTypes.forEach(programType => {
                accessRules.targetProgramTypes[programType] = true;
            });
            cohorts.forEach(cohort => {
                const programType = cohort.programType;
                const institutionId = Cohort.institutionID(programType);
                if (!accessRules.targetCohortIdsByInstitution[institutionId]) {
                    accessRules.targetCohortIdsByInstitution[institutionId] = [];
                }
                if (!accessRules.targetInstitutionIds[institutionId]) {
                    accessRules.targetInstitutionIds[institutionId] = false;
                }
                if (!accessRules.targetProgramTypes[programType]) {
                    accessRules.targetProgramTypes[programType] = false;
                }
                if (initialCohortIds.includes(cohort.id)) {
                    accessRules.targetCohortIdsByInstitution[institutionId].push(cohort.id);
                }
            });
            return accessRules;
        },
    },

    target_institution_ids: {
        fromForm: values => {
            const institutionAccessMap = values.accessRules.targetInstitutionIds;
            const val = Object.keys(institutionAccessMap).filter(institutionId => institutionAccessMap[institutionId]);
            return val;
        },
    },

    target_program_types: {
        fromForm: values => {
            const programAccessMap = values.accessRules.targetProgramTypes;
            const val = Object.keys(programAccessMap).filter(programType => programAccessMap[programType]);
            return val;
        },
    },

    target_cohort_ids: {
        fromForm: values =>
            Object.values(values.accessRules.targetCohortIdsByInstitution).reduce((acc, cohortIds) => {
                acc.push(...cohortIds);
                return acc;
            }, []),
    },
});

export { toForm, fromForm };
