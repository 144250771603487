import { type AnyObject } from '@Types';

import meetupMarker from 'images/student_network_events_meetup_marker.png';
import conferenceMarker from 'images/student_network_events_conference_marker.png';
import companyVisitMarker from 'images/student_network_events_company_visit_marker.png';
import specialEventMarker from 'images/student_network_events_special_event_marker.png';
import careerFairMarker from 'images/student_network_events_career_fair_marker.png';
import bookClubMarker from 'images/student_network_events_book_club_icon.png';
import onlineEventMarker from 'images/student_network_events_online_event_icon.png';
import documentaryClubMarker from 'images/student_network_events_documentary_club_icon.png';
import podcastClubMarker from 'images/student_network_events_podcast_club_icon.png';
import virtualMeetupMarker from 'images/student_network_events_virtual_meetup_marker.png';

import { type EventType, type EventTypeConfig } from './StudentNetworkEvent.types';

const EVENT_TYPE_CONFIGS: EventTypeConfig[] = [
    {
        key: 'meetup',
        mappable: true,
        eventTypeFiltersOrder: 1,
        icon: {
            src: meetupMarker,
            width: 23,
            height: 23,
        },
    },
    {
        key: 'conference',
        mappable: true,
        eventTypeFiltersOrder: 2,
        icon: {
            src: conferenceMarker,
            width: 23,
            height: 23,
        },
    },
    {
        key: 'company_visit',
        mappable: true,
        eventTypeFiltersOrder: 3,
        icon: {
            src: companyVisitMarker,
            width: 23,
            height: 23,
        },
    },
    {
        key: 'special_event',
        mappable: true,
        eventTypeFiltersOrder: 4,
        icon: {
            src: specialEventMarker,
            width: 23,
            height: 23,
        },
    },
    {
        key: 'virtual_meetup',
        mappable: true,
        eventTypeFiltersOrder: 5,
        icon: {
            src: virtualMeetupMarker,
            width: 25,
            height: 24,
        },
        hidesLocationDetails: true,
    },
    {
        key: 'admissions_event',
        mappable: false,
        icon: {
            src: virtualMeetupMarker,
            width: 25,
            height: 24,
        },
    },
    {
        key: 'career_fair',
        mappable: true,
        eventTypeFiltersOrder: 6,
        visibleInEventTypeFiltersOnlyWhenAny: true,
        icon: {
            src: careerFairMarker,
            width: 23,
            height: 23,
        },
    },
    {
        key: 'book_club',
        mappable: false,
        icon: {
            src: bookClubMarker,
            width: 18,
            height: 20,
        },
    },
    {
        key: 'online_event',
        mappable: false,
        icon: {
            src: onlineEventMarker,
            width: 25,
            height: 24,
        },
    },
    {
        key: 'documentary_club',
        mappable: false,
        icon: {
            src: documentaryClubMarker,
            width: 22,
            height: 20,
        },
    },
    {
        key: 'podcast_club',
        mappable: false,
        icon: {
            src: podcastClubMarker,
            width: 18,
            height: 21,
        },
    },
    {
        key: 'ted_talk_club',
        mappable: false,
        icon: {
            src: virtualMeetupMarker,
            width: 25,
            height: 24,
        },
    },
    {
        key: 'student_organization_events',
        mappable: false,
        icon: {
            src: virtualMeetupMarker,
            width: 25,
            height: 24,
        },
    },
    {
        key: 'ask_me_anything',
        mappable: false,
        icon: {
            src: virtualMeetupMarker,
            width: 25,
            height: 24,
        },
    },
    {
        key: 'office_hours',
        mappable: false,
        supportsAttendanceTracking: true,
        icon: {
            src: virtualMeetupMarker,
            width: 25,
            height: 24,
        },
    },
    {
        key: 'academic_webinar',
        mappable: false,
        supportsAttendanceTracking: true,
        icon: {
            src: virtualMeetupMarker,
            width: 25,
            height: 24,
        },
    },
];

const EVENT_TYPE_CONFIGS_MAP = EVENT_TYPE_CONFIGS.reduce<AnyObject>((accumulator, eventTypeConfig) => {
    accumulator[eventTypeConfig.key] = eventTypeConfig;
    return accumulator;
}, {}) as Record<EventType, EventTypeConfig>;

export { EVENT_TYPE_CONFIGS, EVENT_TYPE_CONFIGS_MAP };
