import { EVENT_TYPE_CONFIGS_MAP } from './StudentNetworkEventTypeConfigs';

export function eventTypeIcon(eventType) {
    return eventType && EVENT_TYPE_CONFIGS_MAP[eventType].icon;
}

export function icon(studentNetworkEvent) {
    return studentNetworkEvent.event_type && eventTypeIcon(studentNetworkEvent.event_type);
}

export function eventTypeIsMappable(eventType) {
    return eventType ? EVENT_TYPE_CONFIGS_MAP[eventType].mappable : false;
}

export function mappable(studentNetworkEvent) {
    return studentNetworkEvent.event_type && eventTypeIsMappable(studentNetworkEvent.event_type);
}

export function eventTypeHidesLocationDetails(eventType) {
    return eventType ? EVENT_TYPE_CONFIGS_MAP[eventType].hidesLocationDetails : false;
}

export function hidesLocationDetails(studentNetworkEvent) {
    return studentNetworkEvent.event_type && eventTypeHidesLocationDetails(studentNetworkEvent.event_type);
}
