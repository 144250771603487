import * as Yup from 'yup';
import { eventTypeIsMappable } from './helpers';

const requiredStringIfMappable = Yup.string().when('event_type', {
    is: eventType => eventTypeIsMappable(eventType),
    then: Yup.string().required(),
    otherwise: Yup.string().nullable(),
});

const requiredObjectIfMappable = Yup.object().when('event_type', {
    is: eventType => eventTypeIsMappable(eventType),
    then: Yup.object().required(),
    otherwise: Yup.object().nullable(),
});

const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().required(),
    event_type: Yup.string().required(),
    start_time: Yup.date().when('date_tbd', {
        is: false,
        then: Yup.date().required(),
        otherwise: Yup.date().nullable(),
    }),
    end_time: Yup.date().when('date_tbd', {
        is: false,
        then: Yup.date()
            .required()
            .test(
                'end after start',
                'End time must be after start time',
                (endTime, { parent }) => endTime > parent.start_time,
            ),
        otherwise: Yup.date().nullable(),
    }),
    date_tbd_description: Yup.string().when('date_tbd', {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable(),
    }),
    external_rsvp_url: Yup.string()
        .nullable()
        .test(
            'external_rsvp_url_undefined',
            'External RSVP URL must be blank if RSVP Not Required',
            (externalRsvpUrl, { parent }) => (parent.rsvp_status === 'not_required' ? !externalRsvpUrl : true),
        )
        .test(
            'external_rsvp_url_defined',
            'External RSVP URL must not be blank if RSVP Required',
            (externalRsvpUrl, { parent }) =>
                parent.rsvp_status === 'required' ? !!externalRsvpUrl && typeof externalRsvpUrl === 'string' : true,
        ),
    host: Yup.string().nullable(),
    rsvp_count: Yup.number()
        .test('rsvp_count', 'RSVP Count cannot be a negative number', rsvpCount => rsvpCount >= 0)
        .default(0),
    attendance_count: Yup.number()
        .test(
            'attendance_count',
            'Attendance Count cannot be a negative number',
            attendanceCount => attendanceCount >= 0,
        )
        .default(0),
    place: requiredObjectIfMappable,
    location_name: requiredStringIfMappable,
    timezone: requiredStringIfMappable,
    accessRules: Yup.object().test(
        'accessRules',
        'Select at least one institution, program, or cohort',
        (accessRules, { parent }) => {
            if (!parent.published) {
                return true;
            }

            if (Object.values(accessRules.targetInstitutionIds).includes(true)) {
                return true;
            }

            if (Object.values(accessRules.targetProgramTypes).includes(true)) {
                return true;
            }

            let cohortSelected = false;
            Object.values(accessRules.targetCohortIdsByInstitution).forEach(list => {
                if (list.length > 0) {
                    cohortSelected = true;
                }
            });
            return cohortSelected;
        },
    ),
    target_student_statuses: Yup.array().when('published', {
        is: true,
        then: Yup.array().min(1),
        otherwise: Yup.array().notRequired(),
    }),
});

export default validationSchema;
